import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import QrCodeField from '../QrCodeField';
import SaveIcon from '@material-ui/icons/Save';
import {
  showNotification as showNotificationAction,
  TextInput,
  Button,
} from 'react-admin';
import { fetchJson } from '../../../shared/utils/HttpUtils';
import { refreshView as refreshViewAction } from 'react-admin';
import { connect } from 'react-redux';

class TwoFaComponent extends Component {
  state = {
    code: '',
  };

  handleOtpCodeChange = (event) => {
    let value = event.target.value;
    this.setState((state) => ({
      ...state,
      code: value,
    }));
  };

  handleOtpSave = (event) => {
    let code = this.state.code;
    if (code == null || code.length !== 6) {
      this.props.showNotification(
        'Error: The entered code is not valid!',
        'warning'
      );
    }

    const httpClient = (url, options = {}) => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
      }

      const token = localStorage.getItem('token');
      options.headers.set('Authorization', `Bearer ${token}`);

      return fetchJson(url, options);
    };

    httpClient(`admin/actions/save-2fa`, {
      method: 'POST',
      body: JSON.stringify({
        secret: this.props.record != null ? this.props.record.otpSecretTmp : '',
        otp: code,
      }),
    })
      .then((response) => {
        this.props.refresh();
      })
      .catch((e) => {
        this.props.showNotification('Error: ' + e.message, 'warning');
      });
  };

  render() {
    let record = this.props.record;

    let content = null;
    if (record != null) {
      if (record.otpSecret != null) {
        content = (
          <Fragment>
            <p>
              <TextInput disabled source="email" record={record} />
            </p>
            <p>
              <TextInput disabled source="otpIssuer" record={record} />
            </p>
            <p>Your 2FA has been set up successfully!</p>
          </Fragment>
        );
      } else {
        content = (
          <Fragment>
            <p>
              <TextInput disabled source="email" record={record} />
            </p>
            <p>
              <TextInput disabled source="otpIssuer" record={record} />
            </p>
            <p>
              <QrCodeField source="otpUrlTmp" record={record} />
            </p>
            <p>
              <TextInput
                source="otpCode"
                record={record}
                onChange={this.handleOtpCodeChange}
              />
            </p>
            <p>
              <Button label="SAVE" onClick={this.handleOtpSave}>
                <SaveIcon />
              </Button>
            </p>
          </Fragment>
        );
      }
    }

    return (
      <Fragment>
        <div>{content}</div>
      </Fragment>
    );
  }
}

TwoFaComponent.propTypes = {
  record: PropTypes.object,
};

export default connect(null, {
  showNotification: showNotificationAction,
  refresh: refreshViewAction,
})(TwoFaComponent);
