import React from 'react';

const UserInfoWrapper = ({ source, record = {}, label='', as }) => {
  let userInfo = record['userInfo'];

  if (userInfo != null) {
    return React.createElement(as, {
      record: userInfo,
      source: source,
      label: label,
    });
  } else {
    return null;
  }
};

export default UserInfoWrapper;
