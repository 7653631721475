import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin, showNotification, Notification } from 'react-admin';
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  FormGroup,
  Button,
  Paper,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import loginBck from '../../assets/images/banner_bck.jpg';
import ReCAPTCHA from 'react-google-recaptcha';
import { recaptchaKey } from '../../config/AppConfig';

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      code: '',
      error: null,
      requestCode: false,
      loading: false,
    };

    this.recaptchaRef = React.createRef();
  }

  handleChange = (e, key) => {
    let value = e.target.value;

    this.setState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  handleCancel = () => {
    this.setState((state) => ({
      ...state,
      requestCode: false,
      code: '',
      error: null,
    }));
  };

  submit = async (e) => {
    e.preventDefault();

    //Fetch reCaptcha token
    const token = await this.recaptchaRef.current.executeAsync();

    this.setState((state) => ({
      ...state,
      loading: true,
      error: null,
    }));

    let request = new Request('/api/user/signin-2fa', {
      method: 'POST',
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
        token: token,
        admin: true,
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        } else {
          this.setState((state) => ({
            ...state,
            requestCode: true,
            loading: false,
          }));
        }
      })
      .catch((e) => {
        let error = 'Invalid e-mail / password combination!';

        this.setState((state) => ({
          ...state,
          error: error,
          loading: false,
        }));
        showNotification(error, 'warning');
      });

    this.recaptchaRef.current.reset();
  };

  submit2fa = async (e) => {
    e.preventDefault();

    //Fetch reCaptcha token
    const token = await this.recaptchaRef.current.executeAsync();

    // Dispatch the userLogin action (injected by connect)
    this.props.userLogin({
      username: this.state.email,
      password: this.state.password,
      code: this.state.code,
      token: token,
    });

    this.recaptchaRef.current.reset();
  };

  render() {
    let form;
    if (this.state.requestCode) {
      form = (
        <form id="2fa-form" onSubmit={this.submit2fa}>
          <FormGroup style={{ paddingBottom: '0.7rem' }}>
            <FormControl>
              <InputLabel htmlFor="email">Code</InputLabel>
              <Input
                id="code"
                name="code"
                type="number"
                aria-describedby="code"
                value={this.state.code}
                required
                onChange={(e) => this.handleChange(e, 'code')}
              />
            </FormControl>
          </FormGroup>
          <FormGroup style={{ paddingTop: '1rem' }}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={this.state.loading}
            >
              Verify
            </Button>
            <br />
            <Button onClick={this.handleCancel} disabled={this.state.loading}>
              Cancel
            </Button>
          </FormGroup>
        </form>
      );
    } else {
      form = (
        <form id="login-form" onSubmit={this.submit}>
          <FormGroup style={{ paddingBottom: '0.7rem' }}>
            <FormControl>
              <InputLabel htmlFor="email">Email address</InputLabel>
              <Input
                id="email"
                name="email"
                type="email"
                aria-describedby="email"
                value={this.state.email}
                required
                onChange={(e) => this.handleChange(e, 'email')}
              />
            </FormControl>
          </FormGroup>
          <FormGroup style={{ paddingBottom: '0.7rem' }}>
            <FormControl>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                name="password"
                type="password"
                aria-describedby="password"
                value={this.state.password}
                required
                onChange={(e) => this.handleChange(e, 'password')}
              />
            </FormControl>
          </FormGroup>
          <FormGroup style={{ paddingTop: '1rem' }}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={this.state.loading}
            >
              Login
            </Button>
          </FormGroup>
        </form>
      );
    }

    const styles = {
      paperContainer: {
        backgroundImage: `url(${loginBck})`,
        backgroundSize: '100% 100%',
      },
    };

    return (
      <Paper style={styles.paperContainer}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          {this.state.error != null ? (
            <Grid
              item
              xs={8}
              style={{
                backgroundColor: 'white',
                padding: '0.7rem',
                margin: '1rem',
                borderRadius: '0.3rem',
              }}
            >
              <p style={{ color: 'red' }}>{this.state.error}</p>
            </Grid>
          ) : null}
          <Grid
            item
            xs={8}
            style={{
              backgroundColor: 'white',
              padding: '1.5rem',
              borderRadius: '0.3rem',
            }}
          >
            {form}
            <Notification />
          </Grid>
        </Grid>
        <ReCAPTCHA
          ref={this.recaptchaRef}
          size="invisible"
          sitekey={recaptchaKey}
        />
      </Paper>
    );
  }
}

export default connect(undefined, { userLogin, showNotification })(
  withRouter(LoginPage)
);
