import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';

const TransactionsGraphChart = (props) => {
  let transactionsChart = null;
  if (props.state.statsLoaded) {
    let dataMap = [];
    let dateList = [];
    props.data.forEach((data, i) => {
      dataMap[i] = [];
      data.forEach((elem) => {
        let mmt = moment(elem.count).format('YYYY-MM-DD');

        dataMap[i][mmt] = elem.sum;
        dateList.push(mmt);
      });
    });
    dateList = dateList.sort((a, b) => a.valueOf() - b.valueOf());

    //Create labels
    let labels = [dateList[0]];
    let j = 0;
    for (let i = 0; i < dateList.length; i++) {
      if (labels[j].valueOf() !== dateList[i].valueOf()) {
        labels.push(dateList[i]);
        j++;
      }
    }
    labels = labels.map((elem) => {
      if (elem != null) {
        // return elem.format('YYYY-MM-DD');
        return elem;
      }

      return '';
    });

    //Create data
    let series = [];
    for (let i = 0; i < dataMap.length; i++) {
      let seriesData = [];
      labels.forEach((label) => {
        if (dataMap[i][label] != null) {
          seriesData.push(dataMap[i][label]);
        } else {
          seriesData.push(0);
        }
      });
      series.push({
        name: props.names[i],
        data: seriesData,
        type: props.types[i],
      });
    }

    //Create options
    let options = {
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: labels,
      },
    };

    // console.log("------------");
    // console.log("OPTIONS: ", options);
    // console.log("SERIES: ", series);
    // console.log("HEIGHT: ", props.height);
    // console.log("------------");

    transactionsChart = (
      <Chart options={options} series={series} height={props.height} />
    );
  }

  return <Fragment>{transactionsChart}</Fragment>;
};

export default TransactionsGraphChart;
