import { List, Datagrid, TextField, TextInput, DateInput } from 'react-admin';
import CustomDateField from '../../shared/components/CustomDateField';
import { ListActions } from '../../shared/components/ListActions';
import { useDataGridStyles } from '../../shared/styles/DataGridStyles';

export const ActionsList = (props) => {
  const classes = useDataGridStyles();

  return (
    <List
      {...props}
      filters={filters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      bulkActionButtons={false}
      actions={<ListActions filters={filters} />}
    >
      <Datagrid classes={classes}>
        <TextField source="user.email" label="Email" />
        <TextField source="description" />
        <TextField source="device" label="Agent" />
        <TextField source="page" />
        <TextField source="ip" />
        <CustomDateField source="createdAt" format="LLLL" />
      </Datagrid>
    </List>
  );
};

const filters = [
  <TextInput label="Search by user e-mail" source="email" alwaysOn />,
  <TextInput label="Search by page" source="pageName" alwaysOn />,
  <DateInput
    label="Start Date"
    source="from"
  />,
  <DateInput
    label="End Date"
    source="to"
  />
];
