import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ArrayField,
  NumberField,
  Filter,
  SelectInput,
  Show,
  SimpleShowLayout,
  DateInput, 
  ExportButton
} from 'react-admin';
import ExceptionMessageField from './components/ExceptionMessageField';
import CustomDateField from '../../shared/components/CustomDateField';
import { useDataGridStyles } from '../../shared/styles/DataGridStyles';

export const LogList = (props) => {
  const classes = useDataGridStyles();

  return (
    <List
      {...props}
      filters={<LogsFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      perPage={25}
      bulkActionButtons={false}
      actions={<ExportButton maxResults="50000" />}
    >
      <Datagrid rowClick="expand" expand={<LogShow />} classes={classes}>
        <TextField source="id" />
        <TextField source="levelString" label="Level" />
        <ExceptionMessageField source="formattedMessage" label="Message" />
        <CustomDateField source="timestmp" format="LLL" />
      </Datagrid>
    </List>
  );
};

export const LogShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ArrayField source="exceptionLines">
          <Datagrid>
            <NumberField source="id.i" label="Ln" />
            <TextField source="traceLine" label="Trace" />
          </Datagrid>
        </ArrayField>
        <TextField source="loggerName" />
        <NumberField source="callerLine" />
        <TextField source="threadName" />
        <TextField source="arg0" label="Detail 1" />
        <TextField source="arg1" label="Detail 2" />
        <TextField source="arg2" label="Detail 3" />
        <TextField source="arg3" label="Detail 4" />
      </SimpleShowLayout>
    </Show>
  );
};

const LogsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Find in logs" source="query" alwaysOn />
    <SelectInput
      source="level"
      choices={[
        // { id: null, name: 'ALL' },
        { id: 'DEBUG', name: 'DEBUG' },
        { id: 'INFO', name: 'INFO' },
        { id: 'WARN', name: 'WARN' },
        { id: 'ERROR', name: 'ERROR' },
      ]}
    />
    <DateInput
      label="Start Date"
      source="from"
      alwaysOn
    />
    <DateInput
      label="End Date"
      source="to"
      alwaysOn
    />
    {/* <TextInput label="Search by user e-mail" source="email" />   
        <BooleanInput label="Show CREATED" source="created" /> */}
  </Filter>
);
