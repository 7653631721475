import {Fragment} from "react";
import {TextField} from "react-admin";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

export const UserSysInfoTable = ({ record }) => {
    let table = null;

    if (record.userInfo != null) {
        table = (
            <Fragment>
                <br />
                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
                    System information
                </label>
                <Table style={{ width: 'auto', backgroundColor: '#E8E9EB'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Db ID</TableCell>
                            <TableCell>Fee (%)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        <TableRow hover={true}>
                            <TableCell>
                                <TextField source="id" record={record} />
                            </TableCell>
                            <TableCell>
                                <TextField source="userInfo.percentageFee" record={record} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Fragment>
        );
    }
    return <Fragment>{table}</Fragment>;
};
