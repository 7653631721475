import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const AuthImageField = ({ source, record = {} }) => {
  const useStyles = makeStyles({
    list: {
      display: 'flex',
      listStyleType: 'none',
    },
    image: {
      margin: '0.5rem',
      maxHeight: '10rem',
    },
  });
  const classes = useStyles();

  const src = record[source] + '?auth=' + localStorage.getItem('token');

  let output = null;
  if (record[source] != null) {
    output = (
      <img
        title={record.title}
        alt={record.title}
        src={src}
        className={classes.image}
      />
    );
  }

  return output;
};

AuthImageField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default AuthImageField;
