type ButtonFieldProps<T> = {
  label: string;
  buttonLabel: string;
  record?: T;
  shouldRender?: (Record: T) => boolean;
  onClick: (record: T) => void;
};

export const ButtonField = <T extends unknown>({
  buttonLabel,
  record,
  shouldRender,
  onClick,
}: ButtonFieldProps<T>) => {
  let render = shouldRender ? shouldRender(record!) : Boolean(record);

  return render ? (
    <button style={{ outline: 1 }} onClick={() => onClick(record!)}>
      {buttonLabel}
    </button>
  ) : null;
};
