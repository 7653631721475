import type { FC } from 'react';
import { Datagrid, TextField, NumberField } from 'react-admin';
import TransactionStatusField from './TransactionStatusField';
import CustomDateField from '../../../shared/components/CustomDateField';
import {UserShow} from "../../Users/components/UserShow";
import TransactionTypeField from './TransactionTypeField';
import TransactionStoreField from './TransactionStoreField';
import { useDataGridStyles } from '../../../shared/styles/DataGridStyles';

export const TransactionsGDDatagrid: FC = () => {
  const classes = useDataGridStyles();

  const rowStyle = (record, index) => ({
      backgroundColor: record.type === "PROMO" ? '#efe' : '',
  });

  return (
    <Datagrid rowClick="expand" expand={<UserShow />} rowStyle={rowStyle} classes={classes}>
      <TextField source="prettyId" label="ID" />
      <TextField source="currencyPair" />
      <NumberField
        source="amountCurrency1"
        label="Cash"
        options={{ maximumFractionDigits: 2 }}
      />
      {/* <NumberField source="fee" label="Fee" options={{ maximumFractionDigits: 2 }} /> */}
      {/* <TextField source="destinationAddress" /> */}
      <TransactionStatusField source="status" />
      <TransactionStoreField source="store" />
      <TransactionTypeField source="type" />
      <CustomDateField source="createdAt" format="LLL" />
      <TextField source="id" label="Full ID" />
      {/* <TextField source="destinationWallet" /> */}
      {/* <DateField source="committedAt" /> */}
      {/* <DateField source="updatedAt" />  */}
    </Datagrid>
  );
};
