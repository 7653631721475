import {Fragment} from "react";
import {TextField} from "react-admin";
import CustomDateField from "../../../shared/components/CustomDateField";
import RevealableButton from "./RevealableButton";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

export const UserInfoTable = ({ record }) => {
    let table = null;

    if (record.userInfo != null) {
        table = (
            <Fragment>
                <br />
                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
                    Personal information
                </label>
                <Table style={{ width: 'auto', backgroundColor: '#E8E9EB'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>First name</TableCell>
                            <TableCell>Last name</TableCell>
                            <TableCell>D.O.B</TableCell>
                            <TableCell>SSN</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Zip Code</TableCell>
                            <TableCell>State</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        <TableRow hover={true}>
                            <TableCell>
                                <TextField source="firstName" record={record}  />
                            </TableCell>
                            <TableCell>
                                <TextField source="lastName" record={record} />
                            </TableCell>
                            <TableCell>
                                <CustomDateField
                                    source="userInfo.dateOfBirth"
                                    record={record}
                                    format="L"
                                />
                            </TableCell>
                            <TableCell>
                                <RevealableButton record={record}>
                                    <TextField source="userInfo.ssn" record={record} />
                                </RevealableButton>
                            </TableCell>
                            <TableCell>
                                <TextField source="userInfo.address" record={record} />
                            </TableCell>
                            <TableCell>
                                <TextField source="userInfo.city" record={record} />
                            </TableCell>
                            <TableCell>
                                <TextField source="userInfo.zipCode" record={record} />
                            </TableCell>
                            <TableCell>
                                <TextField source="userInfo.state" record={record} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Fragment>
        );
    }
    return <Fragment>{table}</Fragment>;
};
