import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';

const LogsChart = (props) => {
  let chart = null;
  if (props.state.statsLoaded) {
    let options = {
      labels: ['Debug', 'Info', 'Warn', 'Error'],
      colors: ['#009933', '#0066ff', '#ff6600', '#ff0000'],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.initialSeries[opts.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
                color: '#000',
              },
            },
          },
        },
      },
    };
    chart = (
      <Chart
        options={options}
        series={[
          props.state.stats.log.debug,
          props.state.stats.log.info,
          props.state.stats.log.warn,
          props.state.stats.log.error,
        ]}
        type="donut"
        height={props.height}
      />
    );
  }

  return <Fragment>{chart}</Fragment>;
};

export default LogsChart;
