import React from 'react';
import PropTypes from 'prop-types';

const ExceptionMessageField = ({ source, record = {} }) => {
  let content = null;
  if (record['referenceFlag'] > 0) {
    content = record[source] + ' (more)';
  } else {
    content = record[source];
  }

  return <ul>{content}</ul>;
};

ExceptionMessageField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ExceptionMessageField;
