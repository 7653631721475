import axios from 'axios';

const getToken = (): string => localStorage.getItem('token') ?? '';

const getAuthHttpClient = () => {
  const token = getToken();
  return axios.create({
    baseURL: '/admin',
    timeout: 5000,
    headers: { Authorization: `Bearer ${token}` },
  });
};

//http://localhost:3002/admin/sila/getTransaction/{reference-id}/{user-id} [POST]
export const getSilaTransaction = (referenceId: string, userId: string) => {
  return getAuthHttpClient().post(
    `/sila/getTransaction/${referenceId}/${userId}`
  );
};
