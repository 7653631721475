import React from 'react';
import PropTypes from 'prop-types';

const convertType = (store) => {
  if (store[0] == null) {
    return "Crypto Dispensers";
  }

  return store;
};

const TransactionStoreField = ({ source, record = {} }) => (
  <span>{convertType([record[source]])}</span>
);

TransactionStoreField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default TransactionStoreField;
