// import jwt from 'jsonwebtoken';

export default {
  login: ({ username, password, code, token }) => {
    const request = new Request('/api/user/verify-2fa', {
      method: 'POST',
      body: JSON.stringify({
        email: username,
        password: password,
        code: code,
        token: token,
        admin: true,
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        // const decodedToken = jwt.decode(message);
        localStorage.setItem('token', token);
        localStorage.setItem('permissions', 'admin');
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.status === 401 || error.status === 403 || error.status === 440) {
      localStorage.removeItem('username');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
