import { makeStyles } from '@material-ui/core/styles';

export const useDataGridStyles = makeStyles({
    rowEven: {
        backgroundColor: '#fbfbfb',
    },
    rowOdd: {
        backgroundColor: '#fff',
    },
    greenBackground: {
        backgroundColor: '#efe',
    },
});