import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const DeclinedReasonField = ({ source, record = {} }) => {
  const color = (category) => {
    if (category === 'APPROVE') {
      return 'success.main';
    } else if (category === 'WARNING') {
      return 'warning.main';
    }
    return 'error.main';
  };
  let errors = null;
  if (record[source]) {
    errors = [];

    try {
      JSON.parse(record[source]).forEach((error, i) => {
        errors.push(
          <Box key={i} component="li" color={color(error.category)}>
            {error}
          </Box>
        );
      });
    } catch (err) {
      console.error('Failed to parse errors: ', err);
    }
  }

  return (
    <Typography component="ul" variant="body1">
      {errors}
    </Typography>
  );
};

DeclinedReasonField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DeclinedReasonField;
