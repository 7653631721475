import PropTypes from 'prop-types';

const status = {
  '-1': 'DECLINED',
  0: 'NOT VERIFIED',
  1: 'VERIFIED',
  '-2': 'MANUAL REVIEW',
};

const VerificationStatusField = ({ source, record = {} }) => (
  <span>{status[record[source]]}</span>
);

VerificationStatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default VerificationStatusField;
