import { Component } from 'react';
import { connect } from 'react-redux';
import { userLogout, Responsive } from 'react-admin';
import { withRouter } from 'react-router';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

class LogoutButton extends Component {

  processLogout = () => {
    this.props.userLogout();
  };

  render() {
    //const { classes } = this.props;

    return (
      <Responsive
        xsmall={
          <MenuItem onClick={this.processLogout} /*className={classes.title}*/>
            <ExitIcon /> Logout
          </MenuItem>
        }
        medium={
          <MenuItem onClick={this.processLogout} /*className={classes.title}*/>
            <ExitIcon /> Logout
          </MenuItem>
        }
      />
    );
  }
}
/*export const LogoutButtonWithStyles = (props) => {
  const classes = useStyles();
  return <LogoutButton {...props} classes={classes} />;
};*/
export default connect(undefined, { userLogout })(withRouter(LogoutButton));
