import {ArrayField, Datagrid, NumberField, Show, SimpleShowLayout, TextField} from "react-admin";
import CustomDateField from "../../../shared/components/CustomDateField";
import {Fragment} from "react";
import {UserInfoTable} from "./UserInfoTable";
import {UserPicTable} from "./UserPicTable";
import { UserCommandsTable } from "./UserCommandsTable";
import BlockchainUrlField from "../../Transactions/components/BlockchainUrlField";
import { UserSysInfoTable } from "./UserSysInfoTable";

export const UserShow = (props) => {
    //Make component usable within any component that has a user associated to it
    let propsToPass = { ...props };
    let showAdditionalInfo = false;
    if (props.record != null) {
        if (props.record.user != null) {
            propsToPass.basePath = '/users';
            propsToPass.resource = 'users';
            propsToPass.id = props.record.user.id;
            propsToPass.record = { ...props.record.user };

            showAdditionalInfo = true;
        }
    }

    let show = null;
    if (showAdditionalInfo) {
        show = (
            <Show {...propsToPass} actions={null}>
                <SimpleShowLayout>
                    <UserCommandsTable />
                    <TextField source="email" />
                    <UserInfoTable />
                    <br />
                    <ArrayField source="wallets" >
                        <Datagrid>
                            <TextField source="id" />
                            <TextField source="name" />
                            <TextField source="currency" />
                            <BlockchainUrlField source="address" />
                            <NumberField source="balance" />
                            <NumberField source="reserved" />
                        </Datagrid>
                    </ArrayField>
                    <CustomDateField source="createdAt" label="Created at" format="LL" />
                    <CustomDateField source="updatedAt" label="Updated at" format="LL" />
                </SimpleShowLayout>
            </Show>
        );
    } else {
        show = (
            <Show {...propsToPass} actions={null}>
                <SimpleShowLayout>
                    <UserCommandsTable />
                    <UserInfoTable />
                    <br />
                    <ArrayField source="wallets">
                        <Datagrid>
                            <TextField source="id" />
                            <TextField source="name" />
                            <TextField source="currency" />
                            <BlockchainUrlField source="address" />
                            <NumberField source="balance" />
                            <NumberField source="reserved" />
                        </Datagrid>
                    </ArrayField>
                    {/* <BankAccountsTable /> */}
                    <UserPicTable />
                    {/* <SilaUserInfoTable /> */}
                    <UserSysInfoTable />
                    <CustomDateField source="createdAt" label="Created at" format="LL" />
                    <CustomDateField source="updatedAt" label="Updated at" format="LL" />
                </SimpleShowLayout>
            </Show>
        );
    }

    return <Fragment>{show}</Fragment>;
};