import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';

const UsersChart = (props) => {
  let usersChart = null;
  if (props.state.statsLoaded) {
    let options = {
      labels: ['Approved', 'Declined', 'Pending'],
      colors: ['#009933', '#ff3300', '#0066ff'],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.initialSeries[opts.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
                color: '#000',
              },
            },
          },
        },
      },
    };
    usersChart = (
      <Chart
        options={options}
        series={[
          props.state.stats.users.approved,
          props.state.stats.users.declined,
          props.state.stats.users.pending,
        ]}
        type="donut"
        height={props.height}
      />
    );
  }

  return <Fragment>{usersChart}</Fragment>;
};

export default UsersChart;
