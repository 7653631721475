import { List, Datagrid, TextField, TextInput, DateInput } from 'react-admin';
import CustomDateField from '../../shared/components/CustomDateField';
import { UserShow } from "../Users/components/UserShow";
import { ListActions } from '../../shared/components/ListActions';
import { useDataGridStyles } from '../../shared/styles/DataGridStyles';

export const VisitList = (props) => {
  const classes = useDataGridStyles();

  return (
    <List
      {...props}
      filters={filters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      bulkActionButtons={false}
      actions={<ListActions filters={filters} />}
    >
      <Datagrid rowClick="expand" expand={<UserShow />} classes={classes}>
        <TextField source="id" />
        <TextField source="user.email" />
        <TextField source="ip" />
        <TextField source="device" label="Agent" />
        <CustomDateField source="createdAt" format="LLLL" />
      </Datagrid>
    </List>
  );
};

const filters = [
  <TextInput label="Search by user e-mail" source="email" alwaysOn />,

  <DateInput
    label="Start Date"
    source="from"
  />,
  <DateInput
    label="End Date"
    source="to"
  />
];
