// import { stringify } from "query-string";
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';

/**
 * Maps react-admin queries to a REST API implemented using Java Spring Boot and Swagger
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&pageSize=10
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?id=1234&id=5678
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    if (resource === 'settings') {
      return processProfileResource(type, resource, params);
    } else {
      return processRegularResource(type, resource, params);
    }
  };

  const processProfileResource = (type, resource, params) => {
    let url = '';
    const options = {};

    switch (type) {
      case GET_ONE:
      case UPDATE:
        url = `${apiUrl}/me/${params.id}`;
        break;
      default:
        console.log('Unsupported request type:', type);
    }

    return { url, options };
  };

  const processRegularResource = (type, resource, params) => {
    let url = '';
    const options = {};

    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const filterParams = [];
        Object.keys(params.filter).map((key) =>
          filterParams.push(key + '=' + params.filter[key])
        );

        url = `${apiUrl}/${resource}?page=${page}&pageSize=${perPage}&field=${field}&order=${order}&${filterParams.join(
          '&'
        )}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case 'POST_FOR_ONE':
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      case 'POST_FOR_NONE':
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        if (params.data != null) {
          options.body = JSON.stringify(params.data);
        }
        break;
      case GET_MANY: {
        // const query = {
        //   filter: JSON.stringify({ id: params.ids })
        // };
        let idStr = '';
        // const queryString = params.ids.map(id => idStr + `id=${id}`);
        url = `${apiUrl}/${resource}?${idStr}}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        url = `${apiUrl}/${resource}?page=${page}&pageSize=${perPage}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }

    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { /* headers ,*/ json } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!json.hasOwnProperty('totalElements')) {
          throw new Error(
            'The numberOfElements property must be must be present in the Json response'
          );
        }
        return {
          data: json.content,
          total: parseInt(json.totalElements, 10),
        };
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      default:
        return { data: json };
    }
  };

  const containsNewPics = (params) => {
    return (
      params.data.newPicIdFront != null ||
      params.data.newPicIdBack != null ||
      params.data.newPicSelfie != null ||
      params.data.newPicSsnFront != null ||
      params.data.newPicSsnBack != null
    );
  };

  const processUpdate = (type, resource, params) => {
    const pics = [];

    pics[0] = params.data.newPicIdFront;
    pics[1] = params.data.newPicIdBack;
    pics[2] = params.data.newPicSelfie;
    pics[3] = params.data.newPicSsnFront;
    pics[4] = params.data.newPicSsnBack;

    return Promise.all(pics.map(convertFileToBase64)).then(
      (transformedPics) => {
        params.data.newPicIdFront = null;
        params.data.newPicIdBack = null;
        params.data.newPicSelfie = null;
        params.data.newPicSsnFront = null;
        params.data.newPicSsnBack = null;

        params.data.userInfo.picIdFront = transformedPics[0];
        params.data.userInfo.picIdBack = transformedPics[1];
        params.data.userInfo.picSelfie = transformedPics[2];
        params.data.userInfo.picSsnFront = transformedPics[3];
        params.data.userInfo.picSsnBack = transformedPics[4];

        return processRequest(type, resource, params);
      }
    );
  };

  const processRequest = (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }

    if (type === UPDATE && resource === 'users' && containsNewPics(params)) {
      return processUpdate(type, resource, params);
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then((response) =>
      convertHTTPResponse(response, type, resource, params)
    );
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => processRequest(type, resource, params);
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (file != null) {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;

      reader.readAsDataURL(file.rawFile);
    } else {
      resolve(null);
    }
  });
};
