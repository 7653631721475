import type { FC, ReactNode } from 'react';
import { List } from 'react-admin';
import { TransactionsCryptoDatagrid } from './components/TransactionsCryptoDatagrid';
import { TransactionsBankDatagrid } from './components/TransactionsBankDatagrid';
import { TransactionsGDDatagrid } from './components/TransactionsGDDatagrid';
import { transactionFilter } from './components/TransactionsFilter';
import { TransactionsExtDatagrid } from './components/TransactionsExtDatagrid';
import { gdTransactionFilter } from './components/GdTransactionFilter';
import { ListActions } from '../../shared/components/ListActions';

type TransactionListProps = {
  [id: string]: any;
  options: {
    label: string;
    type: 'CRYPTO' | 'BANK' | 'GD' | 'EXT';
  };
};

type TransactionListType = TransactionListProps['options']['type'];

const transctionTypeSwitch = (type: TransactionListType) =>
((
  {
    CRYPTO: <TransactionsCryptoDatagrid />,
    BANK: <TransactionsBankDatagrid />,
    GD: <TransactionsGDDatagrid />,
    EXT: <TransactionsExtDatagrid />,
  } as { [key in TransactionListType]: ReactNode }
)[type]);

export const TransactionList: FC<TransactionListProps> = (props) => {
  const filters = props.options.type === 'GD' ? gdTransactionFilter : transactionFilter ;

  return (
    <List
      filters={filters}
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      bulkActionButtons={false}
      actions={<ListActions filters={filters} />}
    >
      {transctionTypeSwitch(props.options.type)}
    </List>
  );
};