import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction } from 'react-admin';
import { refreshView as refreshViewAction } from 'react-admin';
import springbootDataProvider from '../../../providers/DataProviders/ra-data-springboot-rest';
import { fetchUtils } from 'react-admin';
import { UPDATE } from 'react-admin';
import { Link } from '@material-ui/core';

class RevealableButton extends Component {
  state = {
    reveal: false,
  };

  handleClick = () => {
    this.setState((state) => ({
      ...state,
      reveal: !state.reveal,
    }));

    this.notifyOfAction();
  };

  notifyOfAction = () => {
    const httpClient = (url, options = {}) => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
      }

      const token = localStorage.getItem('token');
      options.headers.set('Authorization', `Bearer ${token}`);

      return fetchUtils.fetchJson(url, options);
    };
    const dataProvider = springbootDataProvider('/admin', httpClient);
    const { record } = this.props;

    let requestBody = {
      page: '/admin/actions',
      description:
        'ADMIN ACTION: Requested ssn reveal for user id: ' + record.id,
    };
    dataProvider(UPDATE, 'actions/admin-action', {
      id: record.id,
      data: requestBody,
    });
  };

  render() {
    let content;
    if (this.state.reveal) {
      content = this.props.children;
    } else {
      content = (
        <Link component="button" onClick={this.handleClick}>
          reveal
        </Link>
      );
    }

    return <Fragment>{content}</Fragment>;
  }
}

RevealableButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(null, {
  showNotification: showNotificationAction,
  refresh: refreshViewAction,
})(RevealableButton);
