import {BooleanInput, Edit, FormTab, NumberInput, TabbedForm, TextInput} from "react-admin";
import CommentComponent from "./CommentComponent";
import VerificationStatusField from "./VerificationStatusField";
import UploadableImageField from "./UploadableImageField";
import {UserEditToolbar} from "./UserEditToolbar";

export const UserEdit = (props) => {
    return (
        <Edit {...props}>
            <TabbedForm
                title="Edit user"
                toolbar={<UserEditToolbar />}
                submitOnEnter={false}
            >
                <FormTab label="summary">
                    <CommentComponent source="Status" />
                    <VerificationStatusField source="verificationStatus" />
                    <TextInput source="email" />
                    <TextInput source="firstName" />
                    <TextInput source="lastName" />
                    <BooleanInput source="verifiedPhone" />
                    <BooleanInput source="verifiedEmail" />
                    <BooleanInput source="blocked" />
                </FormTab>
                <FormTab label="info">
                    <TextInput source="userInfo.dateOfBirth" label="D.O.B" />
                    <TextInput source="userInfo.ssn" label="Social Security Number" />
                    <TextInput source="userInfo.phoneNumber" label="Phone number" />
                    <TextInput source="userInfo.address" label="Address" />
                    <TextInput source="userInfo.zipCode" label="Zip Code" />
                    <TextInput source="userInfo.city" label="City" />
                    <TextInput source="userInfo.state" label="State" />
                </FormTab>
                <FormTab label="documents">
                    <UploadableImageField source="picIdFront" label="ID Front" />
                    <UploadableImageField source="picIdBack" label="ID Back" />
                    <UploadableImageField source="picSelfie" label="Selfie" />
                    <UploadableImageField source="picSsnFront" label="SSN Card Front" />
                    <UploadableImageField source="picSsnBack" label="SSN Card Back" />
                </FormTab>
                <FormTab label="properties">
                    <NumberInput source="verificationStatus" />
                    <CommentComponent source="-1: DECLINED | 0: PENDING | 1: APPROVED" />
                    <NumberInput source="userInfo.requestCount" label="Total requests" />
                    <TextInput source="userInfo.percentageFee" label="Fee (%)" />
                    <BooleanInput source="infoSubmitted" />
                    <BooleanInput source="manualReview" />
                    <BooleanInput source="admin" />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};