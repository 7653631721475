import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { refreshView as refresh, showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { fetchJson } from '../../../shared/utils/HttpUtils';

// console.log(recored[source]);

class CustomUrlField extends React.Component {
  requestOnfidoCheck = (event) => {
    const httpClient = (url, options = {}) => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
      }

      const token = localStorage.getItem('token');
      options.headers.set('Authorization', `Bearer ${token}`);

      return fetchJson(url, options);
    };

    this.props.showNotification('Checking user status with Onfido ...');

    httpClient(`admin/actions/onfido-check`, {
      method: 'POST',
      body: JSON.stringify({
        userInfoId: this.props.record.id,
      }),
    })
      .then((response) => {
        console.log('Onfido check completed');
        this.props.showNotification('Onfido check completed');
        this.props.refresh();
      })
      .catch((e) => {
        this.props.showNotification('Error: ' + e.message, 'warning');
      });
  };
  render() {
    const { source, label, record } = this.props;
    if (record[source] != null) {
      return (
        <div>
          <Button
            variant="outlined"
            color="default"
            href={record[source]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {label}
          </Button>
          &nbsp;&nbsp;
          {/* <Button
            variant="outlined"
            color="primary"
            onClick={this.requestOnfidoCheck}
          >
            Re-check
          </Button> */}
        </div>
      );
    }

    return null;
  }
}

CustomUrlField.propTypes = {
  label: PropTypes.string.isRequired,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default connect(null, { refresh, showNotification })(CustomUrlField);
