import PropTypes from 'prop-types';
import { Link } from '@mui/material';

const BlockchainUrlField = ({ source, record = {} }) => {

  const to = "https://www.blockchain.com/explorer/search?search=" + record[source];

  let output = null;
  if (record[source] != null) {
    output = (
        <Link to={to} target='_blank' onClick={() => window.open(to, '_blank').focus()} style={{ cursor: 'pointer' }}>
            { record[source] }
        </Link>
    );
  }

  return output;
};

BlockchainUrlField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default BlockchainUrlField;