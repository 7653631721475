import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';

const TransactionsChart = (props) => {
  let transactionsChart = null;
  let labels = ['Crypto', 'Fiat'];
  if (props.labels != null) {
    labels = props.labels;
  }

  if (props.state.statsLoaded) {
    let options = {
      labels: labels,
      colors: ['#fc9803', '#009933'],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.initialSeries[opts.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
                color: '#000',
              },
            },
          },
        },
      },
    };
    transactionsChart = (
      <Chart
        options={options}
        series={props.series}
        type="pie"
        height={props.height}
      />
    );
  }

  return <Fragment>{transactionsChart}</Fragment>;
};

export default TransactionsChart;
