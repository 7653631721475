import type { FC } from 'react';
import { Datagrid, TextField, NumberField } from 'react-admin';
import TransactionStatusField from './TransactionStatusField';
import {UserShow} from "../../Users/components/UserShow";
import CustomDateField from '../../../shared/components/CustomDateField';
import BlockchainUrlField from './BlockchainUrlField';
import { useDataGridStyles } from '../../../shared/styles/DataGridStyles';

export const TransactionsExtDatagrid: FC = (source, record = {}) => {
  const classes = useDataGridStyles();

  return (
    <Datagrid rowClick="expand" expand={<UserShow />} classes={classes}>
      <TextField source="prettyId" label="ID" />
      <TextField source="currencyPair" />
      <NumberField
        source="amountCurrency1"
        label="Cash"
        options={{ maximumFractionDigits: 2 }}
      />
      <NumberField
        source="amountCurrency2"
        label="Crypto"
        options={{ maximumFractionDigits: 8 }}
      />
      <TextField source="referenceId" />
      <NumberField
        source="fee"
        label="Fee"
        options={{ maximumFractionDigits: 2 }}
      />
      <TransactionStatusField source="status" />
      <BlockchainUrlField source="destinationAddress" />
      <CustomDateField source="createdAt" format="LLL" />
      <TextField source="id" label="Full ID" />
      {/* <TextField source="destinationWallet" /> */}
      {/* <TextField source="store" /> */}
      {/* <DateField source="committedAt" /> */}
      {/* <DateField source="updatedAt" />  */}
    </Datagrid>
  );
};
