import {Fragment} from "react";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export const UserCommandsTable = ({ record }) => {
    let table = null;

    if (record.userInfo != null) {
        table = (
            <Fragment>
                <br />
                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
                    View
                </label>
                <Table style={{ width: 'auto', backgroundColor: '#E8E9EB'}}>
                    <TableBody >
                        <TableRow hover={true}>
                            <TableCell>
                                <Button
                                    color="primary"
                                    component={Link}
                                    to={{
                                        pathname: '/transactions/crypto',
                                        search: `filter=${JSON.stringify({ email: record.email })}`,
                                    }}
                                >Crypto TRX</Button>
                            </TableCell>
                            <TableCell>
                                <Button
                                    color="primary"
                                    component={Link}
                                    to={{
                                        pathname: '/transactions/gd',
                                        search: `filter=${JSON.stringify({ email: record.email })}`,
                                    }}
                                >GreenDot TRX</Button>
                            </TableCell>
                            <TableCell>
                                <Button
                                    color="primary"
                                    component={Link}
                                    to={{
                                        pathname: '/transactions/ext',
                                        search: `filter=${JSON.stringify({ email: record.email })}`,
                                    }}
                                >External TRX</Button>
                            </TableCell>
                            <TableCell>
                                <Button
                                    color="primary"
                                    component={Link}
                                    to={{
                                        pathname: '/visits',
                                        search: `filter=${JSON.stringify({ email: record.email })}`,
                                    }}
                                >Visits</Button>
                            </TableCell>
                            <TableCell>
                                <Button
                                    color="primary"
                                    component={Link}
                                    to={{
                                        pathname: '/actions',
                                        search: `filter=${JSON.stringify({ email: record.email })}`,
                                    }}
                                >Actions</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Fragment>
        );
    }
    return <Fragment>{table}</Fragment>;
};
