import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, UPDATE } from 'react-admin';
import {
  showNotification as showNotificationAction,
  fetchUtils,
} from 'react-admin';
import springbootDataProvider from '../../../providers/DataProviders/ra-data-springboot-rest';
import PersonIcon from '@material-ui/icons/PersonAdd';
import { refreshView as refreshViewAction } from 'react-admin';

class ApproveUserButton extends Component {
  handleClick = () => {
    const httpClient = (url, options = {}) => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
      }

      const token = localStorage.getItem('token');
      options.headers.set('Authorization', `Bearer ${token}`);

      return fetchUtils.fetchJson(url, options);
    };
    const dataProvider = springbootDataProvider('/admin', httpClient);

    const { refresh, record, showNotification } = this.props;
    const updatedRecord = {
      ...record,
      infoSubmitted: true,
      verificationStatus: 1,
      manualReview: 0,
    };
    dataProvider(UPDATE, '/users', { id: record.id, data: updatedRecord })
      .then(() => {
        showNotification('Approved!');
        refresh();
      })
      .catch((e) => {
        showNotification('Error: ' + e.message, 'warning');
      });
  };

  render() {
    return (
      <Button label="APPROVE" onClick={this.handleClick}>
        <PersonIcon />
      </Button>
    );
  }
}

ApproveUserButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(null, {
  showNotification: showNotificationAction,
  refresh: refreshViewAction,
})(ApproveUserButton);
