import {Fragment} from "react";
import VerificationStatusField from "./VerificationStatusField";
import UserInfoWrapper from "./UserInfoWrapper";
import AuthImageField from "./AuthImageField";
import DeclinedReasonField from "./DeclinedReasonField";
import CustomUrlField from "./CustomUrlField";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

export const UserPicTable = ({ record }) => {
    let table = null;

    if (record.userInfo != null) {
        table = (
            <Fragment>
                <br />
                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
                    Document verification
                </label>
                <Table style={{ width: 'auto' }} >
                    <TableHead>
                        <TableRow>
                            <TableCell>Verification status</TableCell>
                            <TableCell>Document scan (front)</TableCell>
                            <TableCell>Document scan (back)</TableCell>
                            <TableCell>Selfie</TableCell>
                            <TableCell>Warnings</TableCell>
                            <TableCell>Request reference</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover={true}>
                            <TableCell>
                                <VerificationStatusField
                                    source="verificationStatus"
                                    record={record}
                                />
                            </TableCell>
                            <TableCell sx={{color:'#fff'}}>
                                <UserInfoWrapper
                                    source="picIdFront"
                                    record={record}
                                    as={AuthImageField}
                                />
                            </TableCell>
                            <TableCell>
                                <UserInfoWrapper
                                    source="picIdBack"
                                    record={record}
                                    as={AuthImageField}
                                />
                            </TableCell>
                            <TableCell>
                                <UserInfoWrapper
                                    source="picSelfie"
                                    record={record}
                                    as={AuthImageField}
                                />
                            </TableCell>
                            <TableCell>
                                <UserInfoWrapper
                                    source="declinedReason"
                                    as={DeclinedReasonField}
                                    record={record}
                                />
                            </TableCell>
                            <TableCell sx={{color:'#fff'}}>
                                <UserInfoWrapper
                                    source="lastRequestReference"
                                    as={CustomUrlField}
                                    record={record}
                                    label="Open"
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Fragment>
        );
    }
    return <Fragment>{table}</Fragment>;
};
