import React from 'react';
import PropTypes from 'prop-types';

const status = {
  '-3': 'EXPIRED',
  '-2': 'VOIDED',
  '-1': 'FAILED',
  0: 'CREATED',
  1: 'PENDING',
  2: 'COMITTED',
  3: 'FROZEN',
};

// console.log(recored[source]);

const TransactionStatusField = ({ source, record = {} }) => (
  <span>{status[record[source]]}</span>
);

TransactionStatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default TransactionStatusField;
