import React, { Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';

const ConfirmDialog = (props) => {
  return (
    <Fragment>
      <Dialog fullWidth open={props.isOpen} onClose={props.onCancel}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <div>{props.body}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onConfirm}>
            {props.okText != null ? props.okText : 'Ok'}
          </Button>
          <Button onClick={props.onCancel}>
            {props.cancelText != null ? props.cancelText : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ConfirmDialog;
