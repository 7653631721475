import { SelectInput, TextInput, DateInput } from "react-admin/esm";
import moment from 'moment';

export const transactionFilter = [
  <TextInput label="ID..." source="prettyId" alwaysOn />,
  <TextInput label="E-mail..." source="email" alwaysOn />,
  <SelectInput
    source="status"
    label="Status"
    choices={[
      { id: '-3', name: 'EXPIRED' },
      { id: '-2', name: 'VOIDED' },
      { id: '-1', name: 'FAILED' },
      { id: '0', name: 'CREATED' },
      { id: '1', name: 'PENDING' },
      { id: '2', name: 'COMMITTED' },
      { id: '3', name: 'FROZEN' },
    ]}
    alwaysOn
  />,
  <DateInput
    label="Start Date"
    source="from"
    parse={value => {
      // If a date is selected, set time to 00:00:00
      return value ? moment(value).set({ hour: 0, minute: 0, second: 0 }).toISOString() : null;
    }}
  />,
  <DateInput
    label="End Date"
    source="to"
    parse={value => {
      // If a date is selected, set time to 23:59:59
      return value ? moment(value).set({ hour: 23, minute: 59, second: 59 }).toISOString() : null;
    }}
  />
];
