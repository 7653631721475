import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import UserInfoWrapper from './UserInfoWrapper';
import AuthImageField from './AuthImageField';
import { ImageInput, ImageField } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { capitalizeFirst } from '../../../shared/utils/StringUtils';

const UploadableImageField = ({ source, label, record = {} }) => {
  return (
    <Fragment>
      <Table>
        <TableBody>
          <TableRow hover={true}>
            <TableCell width="30%">
              <ImageInput
                source={`new${capitalizeFirst(source)}`}
                label={label}
                accept="image/*"
                maxSize="3000000"
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </TableCell>
            <TableCell>
              <UserInfoWrapper
                source={source}
                as={AuthImageField}
                label={label}
                record={record}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
};

UploadableImageField.propTypes = {
  // image: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default UploadableImageField;
