import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, UPDATE } from 'react-admin';
import {
  showNotification as showNotificationAction,
  fetchUtils,
} from 'react-admin';
import springbootDataProvider from '../../../providers/DataProviders/ra-data-springboot-rest';
import PinIcon from '@material-ui/icons/Cancel';
import { refreshView as refreshViewAction } from 'react-admin';

class DeleteAccountButton extends Component {
  handleClick = () => {
    const httpClient = (url, options = {}) => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
      }

      const token = localStorage.getItem('token');
      options.headers.set('Authorization', `Bearer ${token}`);

      return fetchUtils.fetchJson(url, options);
    };
    const dataProvider = springbootDataProvider('/admin', httpClient);

    const { refresh, record, showNotification } = this.props;
    const updatedRecord = { ...record };
    dataProvider(UPDATE, 'actions/close-user-account', {
      id: record.id,
      data: updatedRecord,
    })
      .then(() => {
        showNotification('Account closed!');
        refresh();
      })
      .catch((e) => {
        showNotification('Error: ' + e.message, 'warning');
      });
  };

  render() {
    return (
      <Button label="CLOSE ACCOUNT" onClick={this.handleClick}>
        <PinIcon />
      </Button>
    );
  }
}

DeleteAccountButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(null, {
  showNotification: showNotificationAction,
  refresh: refreshViewAction,
})(DeleteAccountButton);
