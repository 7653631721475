import { BooleanField, Datagrid, EditButton, EmailField, List, TextField } from 'react-admin';
import VerificationStatusField from "./VerificationStatusField";
import CustomDateField from "../../../shared/components/CustomDateField";
import { userFilter } from "./UserFilter";
import UserInfoWrapper from './UserInfoWrapper';
import { UserShow } from "./UserShow";
import { ListActions } from '../../../shared/components/ListActions';
import { useDataGridStyles } from '../../../shared/styles/DataGridStyles';

export const UserList = (props) => {
    const classes = useDataGridStyles();

    return (
        <List
            filters={userFilter}
            filterDefaultValues={{ manualReview: '0' }}
            {...props}
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
            actions={<ListActions filters={userFilter}/>}
        >
            <Datagrid rowClick="expand" expand={<UserShow />} classes={classes}>
                <TextField source="fullName" />
                <EmailField source="email" className={classes.email} />
                <UserInfoWrapper source="phoneNumber" as={TextField} />
                <BooleanField source="verifiedEmail" />
                <VerificationStatusField source="verificationStatus" />
                <CustomDateField source="createdAt" format="LLL" />
                <BooleanField source="blocked" />
                <BooleanField source="deleted" />
                <EditButton className={classes.email} />
            </Datagrid>
        </List>
    );
};