import React from 'react';
import { Edit, TabbedForm, FormTab } from 'react-admin';
import TwoFaComponent from '../2FA/TwoFaComponent';

export const Settings = ({ staticContext, ...props }) => {
  return (
    <Edit
      id="1"
      resource="settings"
      basePath="/settings"
      title="Settings"
      {...props}
    >
      <TabbedForm title="Edit user" toolbar={false} submitOnEnter={false}>
        <FormTab label="2FA Setup">
          <TwoFaComponent />
          <br />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default Settings;
