import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

// console.log(recored[source]);

const QrCodeField = ({ source, label, record = {} }) => {
  return <QRCode value={record[source]} />;
};

QrCodeField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default QrCodeField;
