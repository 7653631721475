import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

const CustomDateField = ({ source, format, label, record = {} }) => {
  let dateFormat = 'LLLL';
  if (format != null) {
    dateFormat = format;
  }

  let customLabel = null;
  if (label != null) {
    customLabel = (
      <div>
        <br />
        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
          {label}
        </label>
      </div>
    );
  }

  let value = record[source];
  if (source.includes('.')) {
    let parts = source.split('.');

    value = parts.reduce((prev, curr) => prev && prev[curr], record);
  }

  return (
    <Fragment>
      {customLabel}
      <Moment format={dateFormat}>{value}</Moment>
    </Fragment>
  );
};

CustomDateField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default CustomDateField;
