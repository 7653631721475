import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';

const BalanceChart = (props) => {
  let usersChart = null;
  if (props.state.statsLoaded) {
    let options = {
      labels: ['USD'],
      colors: ['#009933'],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.initialSeries[opts.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
                color: '#000',
                formatter: function (val) {
                  return '$' + val.globals.seriesTotals[0];
                },
              },
            },
          },
        },
      },
    };
    usersChart = (
      <Chart
        options={options}
        series={[props.state.stats.wallets.balance_usd]}
        type="donut"
        height={props.height}
      />
    );
  }

  return <Fragment>{usersChart}</Fragment>;
};

export default BalanceChart;
