import React from 'react';
import PropTypes from 'prop-types';

const type = {
  'GD': 'CASH',
  'PROMO': 'PROMO'
};

// console.log(recored[source]);

const TransactionTypeField = ({ source, record = {} }) => (
  <span>{type[record[source]]}</span>
);

TransactionTypeField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default TransactionTypeField;
