import type { ReactNode } from 'react';
import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import springbootDataProvider from '../../providers/DataProviders/ra-data-springboot-rest';
import { fetchUtils, GET_ONE } from 'react-admin';
import { Component } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import {
  showNotification as showNotificationAction,
  userLogout as userLogoutAction,
} from 'react-admin';
import UsersChart from './charts/UsersChart';
import NewUsersChart from './charts/NewUsersChart';
import TransactionsChart from './charts/TransactionsChart';
import TransactionsGraphChart from './charts/TransactionsGraphChart';
import BalanceChart from './charts/BalanceChart';
import LogsChart from './charts/LogsChart';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ConfirmDialog from './components/ConfirmDialog';

type DashboardStateProps = {
  [key: string]: any;
};

class Dashboard extends Component<any, DashboardStateProps> {
  constructor(props) {
    super(props);

    this.state = {
      statsLoaded: false,
      loading: false,
      wallet: null,
      bankBalance: null,
      stats: {
        transaction_counts: {
          crypto: '',
          fiat: '',
        },
        transaction_sums: {
          crypto: '',
          fiat: '',
          gd_by_day: [],
          bank_by_day: [],
        },
        users: {
          approved: '',
          declined: '',
          pending: '',
          new: '',
        },
        wallets: {
          balance: '',
        },
        log: {
          debug: '',
          info: '',
          warn: '',
          error: '',
        },
      },
      transactions: {
        period: {
          group: 'd',
          start: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          end: moment().add(1, 'days').format('YYYY-MM-DD'),
        },
      },
      showConfirmTransfer: false,
    };
  }

  httpClient = (url, options: any = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);

    return fetchUtils.fetchJson(url, options);
  };

  dataProvider = springbootDataProvider('/admin', this.httpClient);

  componentDidMount() {
    this.fetchBalance();
    this.fetchStats();
  }

  fetchBalance() {
    this.dataProvider(GET_ONE, 'dashboard', { id: 0 })
      .then((response) => {
        this.setState((state) => ({
          ...state,
          wallet: response.data.wallet,
        }));
      })
      .catch((e) => {
        if (e.status === 401 || e.status === 440) {
          this.props.userLogout();
        } else {
          this.props.showNotification(
            'Error fetching Main Wallet information!',
            'warning'
          );
        }
      });
  }

  fetchStats() {
    let params = {
      id: 1,
      data: {
        group: this.state.transactions.period.group,
        start: this.state.transactions.period.start,
        end: this.state.transactions.period.end,
      },
    };

    this.setState((state) => ({
      ...state,
      loading: true,
    }));

    this.dataProvider('POST_FOR_ONE', 'dashboard', params)
      .then((response) => {
        this.setState((state) => ({
          ...state,
          stats: response.data,
          statsLoaded: true,
          loading: false,
        }));
      })
      .catch((e) => {
        if (e.status === 401 || e.status === 440) {
          this.props.userLogout();
        } else {
          this.props.showNotification('Error fetching stats!', 'warning');
        }
      });
  }

  handleDateChange(p, event) {
    let date = event.target.value;

    this.setState((state) => {
      let period = {
        ...state.transactions.period,
      };
      period[p] = date;

      return {
        ...state,
        transactions: {
          ...state.transactions,
          period: period,
        },
      };
    });
  }

  showConfirmTransfer = () => {
    this.setState((state) => ({
      ...state,
      showConfirmTransfer: true,
    }));
  };

  hideConfirmTransfer = () => {
    this.setState((state) => ({
      ...state,
      showConfirmTransfer: false,
    }));
  };

  startBankTransfer = () => {
    console.log('Starting bank transfer...');

    this.setState((state) => ({
      ...state,
      loading: true,
    }));

    this.hideConfirmTransfer();

    this.dataProvider('POST_FOR_NONE', 'actions/start-bank-transfer', {})
      .then((response) => {
        this.setState((state) => ({
          ...state,
          loading: false,
        }));
        this.props.showNotification('Bank transfer started!', 'success');
      })
      .catch((e) => {
        if (e.status === 401 || e.status === 440) {
          this.props.userLogout();
        } else {
          let eMsg = 'Unexpected error occurred!';
          if (e.message != null) {
            eMsg = e.message;
          }
          this.props.showNotification(eMsg, 'warning');
        }
      });
  };

  render() {
    let walletsCard: ReactNode | null = null;
    let wallets: ReactNode[] = [];
    let showWalletsCard = false;
    if (this.state.wallet != null) {
      showWalletsCard = true;
      wallets.push(
        <Card style={{ height: '146px' }}>
          <CardContent>
            <strong>Main wallet</strong>
            <br />
            <br />
            Balance: {this.state.wallet.currency}{' '}
            <b>{parseFloat(this.state.wallet.balance).toFixed(8)}</b> <br />
            Address:{' '}
            <span style={{ fontSize: '0.8em' }}>
              {this.state.wallet.address}
            </span>{' '}
            <br />
            Last updated at:{' '}
            <Moment format="LLL">{this.state.wallet.balanceUpdatedAt}</Moment>
          </CardContent>
        </Card>
      );
    }
    if (showWalletsCard) {
      walletsCard = (
        <Grid item md={4} xs={12}>
          {wallets.map((w, i) => (
            <Fragment key={i}>
              {w}
              <br />
            </Fragment>
          ))}
        </Grid>
      );
    }

    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="System Overview" />
              <CardContent>
                <Grid container spacing={2}>
                  {walletsCard}
                  <Grid item md={4} xs={12}>
                    <Card>
                      <CardHeader subheader="Total fiat balance" />
                      <CardContent>
                        <BalanceChart state={this.state} height="200" />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Card>
                      <CardHeader subheader="Log stats" />
                      <CardContent>
                        <LogsChart state={this.state} height="200" />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Users" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <Card>
                      <CardHeader subheader="New user in 24 hours by status" />
                      <CardContent>
                        <NewUsersChart state={this.state} height="200" />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Card>
                      <CardHeader subheader="All users by status" />
                      <CardContent>
                        <UsersChart state={this.state} height="200" />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Transactions" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <Card>
                      <CardHeader subheader="Purchases vs Deposits in last 24 hours (in $)" />
                      <CardContent>
                        <TransactionsChart
                          state={this.state}
                          series={[
                            this.state.stats.transaction_sums.crypto,
                            this.state.stats.transaction_sums.fiat,
                          ]}
                          height="200"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Card>
                      <CardHeader subheader="Transactions in last 24 hours" />
                      <CardContent>
                        <TransactionsChart
                          state={this.state}
                          series={[
                            this.state.stats.transaction_counts.crypto,
                            this.state.stats.transaction_counts.fiat,
                          ]}
                          height="200"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Card>
                      <CardHeader subheader="GD Deposits vs Bank Deposits in last 24 hours (in $)" />
                      <CardContent>
                        <TransactionsChart
                          state={this.state}
                          series={[
                            this.state.stats.transaction_sums.gd,
                            this.state.stats.transaction_sums.bank,
                          ]}
                          labels={['GreenDot', 'Bank']}
                          height="200"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader subheader="Transactions for period" />
                      <CardContent>
                        <form noValidate>
                          <TextField
                            id="date"
                            label="Start"
                            type="date"
                            defaultValue={this.state.transactions.period.start}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(event) =>
                              this.handleDateChange('start', event)
                            }
                          />
                          <span style={{ padding: '0 4px' }} />
                          <TextField
                            id="date"
                            label="End"
                            type="date"
                            defaultValue={this.state.transactions.period.end}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(event) =>
                              this.handleDateChange('end', event)
                            }
                          />
                          <span style={{ padding: '0 4px' }} />
                          <Select
                            labelId="demo-simple-select-label"
                            id="group"
                            value={this.state.transactions.period.group}
                            style={{ height: '2.975rem', paddingTop: '1rem' }}
                            onChange={(event) =>
                              this.handleDateChange('group', event)
                            }
                          >
                            <MenuItem value="d">Day</MenuItem>
                            <MenuItem value="m">Month</MenuItem>
                            <MenuItem value="y">Year</MenuItem>
                          </Select>
                          <span style={{ padding: '0 4px' }} />
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={this.fetchStats.bind(this)}
                            disabled={this.state.loading}
                          >
                            {this.state.loading && (
                              <CircularProgress size={24} />
                            )}
                            {!this.state.loading && 'Go'}
                          </Button>
                        </form>
                        <h4>Green Dot Deposits (USD)</h4>
                        <TransactionsGraphChart
                          state={this.state}
                          data={[this.state.stats.transaction_sums.gd_by_day]}
                          names={['Fiat']}
                          types={['bar']}
                          height="300"
                        />
                        <h4>Fees</h4>
                        <TransactionsGraphChart
                          state={this.state}
                          data={[this.state.stats.transaction_sums.fee_by_day]}
                          names={['Fiat']}
                          types={['bar']}
                          height="200"
                        />
                        <h4>Fiat Transactions (Count)</h4>
                        <TransactionsGraphChart
                          state={this.state}
                          data={[
                            this.state.stats.transaction_sums.fiat_count_by_day,
                          ]}
                          names={['Green Dot and Bank']}
                          types={['line']}
                          height="300"
                        />
                        {/* <h4>Fees</h4>
                                                <TransactionsGraphChart state={this.state} data={this.state.stats.transaction_sums.fee_by_day} height="200" />
                                                <h4>Fiat transactions</h4>
                                                <TransactionsGraphChart state={this.state} data={this.state.stats.transaction_sums.gd_by_day} height="200" /> */}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <ConfirmDialog
          isOpen={this.state.showConfirmTransfer}
          title="Confirm transfer"
          body="This operation cannot be undone. Are you sure you want to proceed?"
          okText="Yes"
          cancelText="Cancel"
          onCancel={this.hideConfirmTransfer}
          onConfirm={this.startBankTransfer}
        />
      </Fragment>
    );
  }
}

export default connect(null, {
  showNotification: showNotificationAction,
  userLogout: userLogoutAction,
})(Dashboard);
