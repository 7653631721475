import React from 'react';
import PropTypes from 'prop-types';

const CommentComponent = ({ source, record = {} }) => <small>{source}</small>;

CommentComponent.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default CommentComponent;
